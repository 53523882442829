<template>
  <div>
    <home-header @design-now-click="handleInviteClick" />
    <home-showcase @design-now-click="handleInviteClick" />
    <home-main @design-now-click="handleInviteClick" />
    <home-footer @design-now-click="handleInviteClick" />
  </div>
</template>

<script>
import HomeFooter from '../components/blocks/Home/Footer'
import HomeHeader from '../components/blocks/Home/Header'
import HomeShowcase from '../components/blocks/Home/Showcase'
import HomeMain from '../components/blocks/Home/Main'
import invitationButton from '../mixins/invitationButton'

export default {
  mixins: [invitationButton],
  components: {
    HomeFooter,
    HomeHeader,
    HomeShowcase,
    HomeMain
  }
}
</script>

<style>
#viewport > footer {
  display: none !important;
}
</style>

<template>
  <section class="showcase">
    <div class="showcase__content">
      <div class="showcase__inner">
        <div class="showcase__teaser">
          <h1 class="showcase__title">
            You are <span class="showcase__accent">magnificent</span>
          </h1>
          <p class="showcase__text">
            And your dress should be, too. We will custom design you an exclusive couture gown that fits your style,
            body and spirit starting at <b>$199</b>.
          </p>
          <span class="home-button -light-shadow" @click.prevent="$emit('design-now-click')">
            <button class="home-button__button">Take our style quiz</button>
          </span>
        </div>
        <div class="showcase__shipping">Free shipping on all orders in&nbsp;the&nbsp;U.S.</div>
      </div>
    </div>
    <social-links class="showcase__socials" />
  </section>
</template>

<script>
import SocialLinks from './SocialLinks'

export default {
  components: {
    SocialLinks
  }
}
</script>

<style lang="scss">
.showcase {
  width: 100%;
  height: 100vh;
  min-height: 645px;
  position: relative;
  background: #000 url('../../../assets/front/showcase/magnificent.jpg') 50% 0 no-repeat;
  background-size: cover;
  color: #fff;

  &__content {
    padding-bottom: 39px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  &__inner {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
  }

  &__teaser {
    margin: 0 14.93%;
    padding-bottom: 115px;
  }

  &__title {
    margin: 0 0 20px -3px;
    font: 100 71px/0.80 'Cormorant Garamond', serif;
    letter-spacing: -0.72px;
  }

  &__accent {
    display: block;
    font-weight: bold;
    font-size: 86px;
    letter-spacing: -2.6px;
  }

  &__text {
    margin: 20px 0 32px 0;
    max-width: 379px;
    font: 100 18px/1.6 'Red Hat Text', sans-serif;
  }

  &__shipping {
    margin-left: 52px;
    padding-left: 36px;
    max-width: 40%;
    font: normal 10px/16px 'Red Hat Text', sans-serif;
    letter-spacing: 2.6px;
    text-transform: uppercase;
    background: url('../../../assets/front/shipping.svg') 0 50% no-repeat;
    color: rgba(255, 255, 255, 0.5);
  }

  &__socials {
    padding: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff;
  }

  &__socials-link {
    margin: 0 17px;
    float: left;
    line-height: 13px;
    text-underline: none;
    cursor: pointer;

    &:after {
      display: none;
    }
  }

  &__socials-icon {
    width: 13px;
    height: 13px;
    object-fit: contain;
    vertical-align: top;
  }

  @media (max-width: 600px) {
    &__content {
      padding-bottom: 14px;
    }

    &__teaser {
      margin: 0 32px 0;
    }

    &__title {
      font-size: 44px;
    }

    &__accent {
      font-size: 52px;
    }

    &__text {
      max-width: 303px;
      font-size: 14px;
    }

    &__shipping {
      margin-left: 32px;
      padding-left: 36px;
      max-width: 30%;
      font-size: 8px;
      line-height: 1.5;
    }

    &__socials {
      padding: 24px 15px 26px;
    }
  }
}
</style>

<template>
  <main class="home-main">
    <section class="home-main__section" id="couture-for-every-woman" style="padding: 0; max-width: 100%">
      <div class="home-main__section">
        <h2 class="home-main__title">Couture For Every Woman</h2>
        <p class="home-main__paragraph">
          We&nbsp;design bridal and special occasion dresses just for you, then fit them to&nbsp;perfection.<br>
          <span class="home-main__text-minor">
            Our luxurious bespoke dresses are meticulously hand sewn by&nbsp;the most talented and experienced artisan
            seamstresses and tailors in&nbsp;Europe and shipped directly to&nbsp;your door.
          </span>
        </p>
      </div>
      <div class="home-main__layer" style="padding-top: 0">
        <div class="home-main__section" style="padding: 0">
          <figure class="home-picture">
            <figcaption class="home-picture__caption">
              We&nbsp;make shopping for a&nbsp;wedding gown, bridesmaid dress, evening gown or&nbsp;any other special
              ocсasion dress fun, effortless and personalized for you.
            </figcaption>
            <img
              class="home-picture__image"
              src="../../../assets/front/dsc-5071.jpg"
              srcset="../../../assets/front/dsc-5071@2x.jpg 2x, ../../../assets/front/dsc-5071@3x.jpg 3x"
              alt=""
            >
          </figure>
        </div>
        <div class="home-main__section">
          <p class="home-main__paragraph">
            High fashion doesn&rsquo;t have to&nbsp;be&nbsp;for a&nbsp;select few. We&nbsp;make it&nbsp;easy and affordable
            for everyone.
          </p>
          <ol class="home-steps">
            <li class="home-steps__item">
              <div class="home-steps__title">
                Tell us about yourself
              </div>
              We love to listen! Answer a few fun questions online about your style, color and garment design preferences.
            </li>
            <li class="home-steps__item -wide">
              <div class="home-steps__title">
                We design your collection
              </div>
              We&nbsp;will immediately design and customize an&nbsp;exclusive, custom collection of&nbsp;bridal gowns,
              bridesmaid dresses or&nbsp;formal or&nbsp;evening gowns and dresses. Choose what you love or&nbsp;ask&nbsp;us
              to&nbsp;further customize the designs.
            </li>
            <li class="home-steps__item">
              <div class="home-steps__title">
                Be unforgettable
              </div>
              Dressed in&nbsp;your Couturme dress, you will look exquisite at&nbsp;your event and feel like the goddess
              you are.
            </li>
          </ol>
        </div>
      </div>
    </section>

    <section class="home-reviews">
      <div class="home-reviews__side">
        <div class="home-reviews__comment">
          <div class="home-reviews__icon"></div>
          <div>
            I&nbsp;was delighted and surprised by&nbsp;the service and&nbsp;It was the easiest getting married!
            The dress was beautiful and elegant, while still not being over the top. It&nbsp;fit my&nbsp;personality
            and the feel&nbsp;I was hoping for. Anyone who wants freedom and ease while feeling beautiful and confident
            should definitely give Couturme a&nbsp;go. Oh! And it&nbsp;didn&rsquo;t break my&nbsp;bank account!
          </div>
          <div class="home-reviews__sign">Jessica Yee-Campbell<br>San Francisco, Ca</div>
        </div>
      </div>
      <div class="home-reviews__picture"></div>
    </section>

    <section class="home-main__section" id="why-we-do-this">
      <h2 class="home-main__title">Why We Do This</h2>
      <p class="home-main__paragraph">
        We&nbsp;wholeheartedly think the fashion industry must switch from the current mass-production model, which
        is&nbsp;the 2nd to&nbsp;3rd largest pollutant of&nbsp;our planet worldwide to&nbsp;the sustainable model
        of&nbsp;on-demand, one-off manufacturing.
      </p>
      <div class="home-theses">
        <p class="home-theses__item">
          At&nbsp;the heart of&nbsp;everything we&nbsp;do&nbsp;lies our commitement to&nbsp;sustainability and ethical
          manufacturing.
        </p>
        <p class="home-theses__item">
          This is&nbsp;a&nbsp;win-win for fashion consumers and our planet alike.
        </p>
      </div>
    </section>

    <home-photos />

    <section id="how-we-do-this">
      <div class="home-main__section">
        <h2 class="home-main__title">How We Do This</h2>
        <p class="home-main__paragraph">
          Our smart online fashion designer creates bespoke dress collections in&nbsp;seconds based
          on&nbsp;style quizzes.
        </p>
      </div>
      <div class="home-main__section" style="padding: 0">
        <home-screens />
      </div>
    </section>

    <section class="home-main__section" id="hand-crafted-to-perfection">
      <h2 class="home-main__title">Hand Crafted To Perfection</h2>
      <p class="home-main__paragraph">
        We&nbsp;celebrate your style preferences and unique taste to&nbsp;customize your dress for your body shape
        and measurements for the perfect fit and style, every time.
      </p>
      <!--
      <div class="home-video">
        <video
          class="home-video__video"
          src=""
          @click="$event.target.play()"
        ></video>
        <div class="home-video__text">
          Becasue of&nbsp;our use of&nbsp;automation technology, we&nbsp;can do&nbsp;this without unnecessary markups.
          Unlike wedding gown boutiques or&nbsp;luxury designer houses, we&nbsp;will not sell you a&nbsp;cookie-cutter
          pre-designed one-style-fits-all gown for thousands (or&nbsp;even tens of&nbsp;thousands) of&nbsp;dollars.
        </div>
      </div>
      -->
    </section>

    <section class="home-main__layer" id="what-it-costs">
      <div class="home-main__section">
        <h2 class="home-main__title">What it costs</h2>
        <div class="home-image">
          <img
            class="home-image__img"
            src="../../../assets/front/what_it_costs/what-it-costs.jpg"
            srcset="../../../assets/front/what_it_costs/what-it-costs@2x.jpg 2x, ../../../assets/front/what_it_costs/what-it-costs@3x.jpg 3x"
            alt=""
          >
        </div>
        <p class="home-main__paragraph">
          <b>Our dresses start at&nbsp;$199.</b><br>
          Evening gowns are $220-$599.<br>
          Wedding gowns start at&nbsp;$699.
        </p>
        <p class="home-main__note">
          At&nbsp;COUTURME our goal is&nbsp;to&nbsp;work with your requests, adjusting our fabrics and design
          complexity to&nbsp;achieve the desired look within your budget.
        </p>
        <span class="home-button">
          <button class="home-button__button" @click="$emit('design-now-click')">Take our style quiz</button>
        </span>
      </div>
    </section>
  </main>
</template>

<script>
import HomePhotos from './Photos'
import HomeScreens from './Screens'

export default {
  components: {
    HomePhotos,
    HomeScreens
  }
}
</script>

<style lang="scss">
.home-main {
  font: 300 16px/1.5 'Red Hat Text', sans-serif;
  /*letter-spacing: -0.3px;*/

  &__layer {
    margin-top: -1px;
    padding: 1px 0 154px;
    background-color: #f1eee9;
  }

  &__section {
    margin: 0 auto;
    padding: 0 32px;
    max-width: 1010px;
  }

  &__title {
    margin: 142px 0 127px;
    font: bold 12px/14px 'Red Hat Text', sans-serif;
    letter-spacing: 3px;
    text-transform: uppercase;
    opacity: 0.6;
    color: #000;
  }

  &__paragraph {
    margin: 121px 0 130px;
    max-width: 748px;
    font: 100 40px/1.6 'Cormorant Garamond', serif;
    letter-spacing: -0.3px;
    color: #000;
  }

  &__note {
    margin-bottom: 63px;
    max-width: 520px;
    letter-spacing: -0.3px;
  }

  &__paragraph + &__note {
    margin-top: -82px;
  }

  &__text-accent {
    font-weight: bold;
  }

  &__text-minor {
    color: #6d6d6d;
  }

  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.26px;

    &__title {
      margin: 6.4em 0 5.6em;
      font-size: 10px;
      line-height: 1.2;
    }

    &__paragraph {
      margin: 2.333em 0;
      font-size: 24px;
    }

    &__paragraph + &__note {
      margin-top: 0;
    }
  }
}

.home-image {
  margin-left: 100px;
  width: 298px;
  height: 298px;
  float: right;

  &__img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }

  @media (max-width: 600px) {
    margin: 0 auto;
    display: block;
    float: none;
  }
}

.home-button {
  display: inline-block;
  margin: 6px 0 0 6px;
  background: url('../../../assets/front/button-bg-black.png') 100% 100%;

  &__button {
    border: 0;
    padding: 2em 4.3em;
    position: relative;
    top: -6px;
    left: -6px;
    font: normal 10px/1.6 'Red Hat Text', sans-serif;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    background-color: #000;
    color: #fff;
  }

  &.-light-shadow {
    background-image: url('../../../assets/front/button-bg-white.png');
  }
}

.home-picture {
  margin: 175px 0 130px;
  position: relative;

  &__image {
    width: 100%;
    object-fit: contain;
    vertical-align: middle;
  }

  &__caption {
    padding: 49px 60px 60px 61px;
    width: 236px;
    position: absolute;
    top: 0;
    left: 0;
    font: 300 16px/1.5 'Red Hat Text', sans-serif;
    letter-spacing: -0.3px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.8);
  }

  @media (min-width: 801px) {
    top: -229px;
    margin: 404px 0 -220px;

    &__caption {
      padding: 49px 60px 30px 61px;
      min-height: 150px;
    }
  }

  @media (max-width: 800px) {
    margin: 32px 0 56px;

    &__caption {
      padding: 0 32px 48px;
      position: static;
      width: auto;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: -0.26px;
    }
  }
}

.home-steps {
  margin: 0 0 26px;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  counter-reset: num;

  &__item {
    max-width: 240px;
    letter-spacing: -0.3px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);

    &.-wide {
      max-width: 290px;
    }

    &:before {
      display: block;
      margin: 0 auto 28px;
      border: 1px dashed #6a6a6a;
      border-radius: 50%;
      width: 54px;
      height: 54px;
      opacity: 0.5;
      text-align: center;
      font: 100 41px/52px 'Red Hat Text', sans-serif;
      color: #6a6a6a;
      content: counter(num);
      counter-increment: num;
    }
  }

  &__title {
    margin-bottom: 1.167em;
    font: bold 1.125em 'Cormorant Garamond', serif;
    line-height: inherit;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;

    &__item {
      margin-bottom: 56px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 400px) {
    &__title {
      font-size: 1.286em;
    }
  }
}

.home-reviews {
  width: 100%;
  min-height: 912px;
  display: flex;
  justify-content: flex-end;
  background-color: #161616;

  &__side {
    width: 50%;
    max-width: 720px;
    align-self: center;
  }

  &__comment {
    padding: 0 calc(73 * 100% / 240 - 73px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font: 100 24px/1.67 'Cormorant Garamond', serif;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.5);
  }

  &__icon {
    margin: 68px 0;
    width: 27px;
    height: 24px;
    background: url('../../../assets/front/heart.svg') 50% 50% no-repeat;
    background-size: contain;
  }

  &__sign {
    margin: 74px 0;
    font-size: 16px;
    line-height: 1;
    color: #fff;
  }

  &__picture {
    width: 50%;
    background: url('../../../assets/front/jessica-wedding.jpg') 0 50% no-repeat;
    background-size: cover;
  }

  @media (max-width: 600px) {
    min-height: 0;
    flex-direction: column;

    &__side {
      width: 100%;
    }

    &__comment {
      font-size: 16px;
      line-height: 2;
    }

    &__icon {
      margin: 71px 0 31px;
      width: 18px;
      height: 16px;
    }

    &__sign {
      margin: 31px 0 71px;
      font-size: 14px;
    }

    &__picture {
      width: 100%;
      min-height: 475px;
      order: -1;
    }
  }
}

.home-theses {
  margin-bottom: 126px;
  display: flex;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.8);

  &__item {
    margin: 0 75px 40px 0;
    max-width: 245px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    &__item {
      margin-right: 0;
    }
  }
}

/*
.home-video {
  display: flex;

  &__video {
    width: 320px;
    height: 180px;
  }

  &__text {
    margin-left: 80px;
    width: 356px;
    align-self: center;
  }
}
*/
</style>

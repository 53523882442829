import { getVariant, getVariantCaption } from '../utils/ab-test/button'
import { trackEvent } from '../utils/analytics'
export default {
  computed: {
    isInvited () {
      return this.$store.getters['referral/invited']
    },
    isLoggedIn () {
      return this.$store.getters['user/isLoggedIn']
    },
    user () {
      return this.$store.state.user.current
    },
    buttonText () {
      // return this.isInvited ? 'Invite Friends' : caption
      return getVariantCaption()
    },
    buttonForceInvitationText () {
      return getVariantCaption()
    },
    buttonTextCode () {
      return getVariant()
    }
  },
  methods: {
    logout () {
      this.$bus.$emit('user-before-logout')
      this.$router.push('/')
    },
    goToAccount () {
      if (this.isLoggedIn) {
        this.$router.push('/my-account')
      } else {
        this.$store.commit('ui/setAuthElem', 'register')
        this.$bus.$emit('modal-show', 'modal-signup')
      }
    },
    showReferral () {
      trackEvent('user-action', 'referral-button-click')
      this.$bus.$emit('modal-show', 'modal-referral')
    },
    invitation () {
      trackEvent('user-action', 'invite-button-click', getVariantCaption(), getVariant())
      this.$modal.show('invite')
    },
    goToQuestionnaire () {
      trackEvent('user-action', 'invite-button-click', getVariantCaption(), getVariant())
      this.$store.commit('questionnaire/newQuestionnaire')
      this.$router.push('/questionnaire')
    },
    handleInviteClick () {
      if (getVariant() === 1) {
        this.invitation()
      } else {
        this.goToQuestionnaire()
      }
    }
  }
}

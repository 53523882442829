const captionVariants = ['Let\'s Design Now', 'Request a Personal Consultation']

export const getVariant = () => {
  if (typeof window === 'undefined') {
    return null
  }
  if (typeof window !== 'undefined' &&
    typeof window.cxApi !== 'undefined' &&
    typeof window.cxApi.chooseVariation === 'function') {
    return window.cxApi.chooseVariation()
  }
  return 0
}

export const getVariantCaption = () => {
  const variant = getVariant()
  if (captionVariants[variant]) {
    return captionVariants[variant]
  }
  return ''
}

export const caption = getVariantCaption()

export const variant = getVariant()

<template>
  <figure class="home-screens">
    <figcaption class="home-screens__side">
      <div class="home-screens__info">
        <div class="home-screens__title">
          {{ curScreen.title }}
        </div>
        <div v-html="curScreen.text"></div>
      </div>
    </figcaption>
    <div class="home-screens__view">
      <img class="home-screens__image" :src="curScreen.imageUrl" alt="">
    </div>
    <div class="home-screens__paginator">
      <span class="home-screens__arrow" @click="prevScreen"></span>
      <span class="home-screens__arrow -right" @click="nextScreen"></span>
    </div>
  </figure>
</template>

<script>
export default {
  data: () => ({
    screens: [
      {
        title: 'Smart online fashion designer',
        text: 'Creates bespoke dress collections in&nbsp;seconds, based on&nbsp;style quiz',
        imageUrl: '/assets/front/screens/ai-designer.jpg'
      },
      {
        title: '3D automation CAD',
        text: 'Instantly drafts custom-fit patterns and prepares orders to&nbsp;be&nbsp;cut and sewn',
        imageUrl: '/assets/front/screens/3d-cad.jpg'
      },
      {
        title: 'Photos for perfect fit',
        text: 'This allows&nbsp;us to&nbsp;generate clients 3D&nbsp;models to&nbsp;get accurate measurements for the perfect fit',
        imageUrl: '/assets/front/screens/photos-fit.jpg'
      }
    ],
    curScreenIndex: 0
  }),

  computed: {
    curScreen () {
      return this.screens[this.curScreenIndex]
    }
  },

  methods: {
    prevScreen () {
      if (this.curScreenIndex > 0) {
        this.curScreenIndex--
      } else {
        this.curScreenIndex = this.screens.length - 1
      }
    },

    nextScreen () {
      if (this.curScreenIndex < this.screens.length - 1) {
        this.curScreenIndex++
      } else {
        this.curScreenIndex = 0
      }
    }
  }
}
</script>

<style lang="scss">
.home-screens {
  margin: 0;
  display: flex;
  position: relative;
  background-color: rgba(241, 238, 233, 0.4);

  &__side {
    box-sizing: border-box;
    padding: 40px 40px 86px;
    flex: 1 1 50%;
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.8)
  }

  &__info {
    max-width: 250px;
  }

  &__title {
    margin-bottom: 0.8em;
    font: bold 24px 'Cormorant Garamond', serif;
    line-height: inherit;
  }

  &__view {
    box-sizing: border-box;
    padding: 10px 11px;
    flex: 1 1 50%;
  }

  &__image {
    width: 100%;
    vertical-align: top;
    object-fit: contain;
  }

  &__paginator {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 132px;
    height: 69px;
    background-color: #fff;
  }

  &__arrow {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    cursor: pointer;

    &:before {
      border: solid rgba(22, 22, 22, 0.6);
      border-width: 0 0 1px 1px;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -4px;
      transform: rotate(45deg);
      content: '';
    }

    &.-right {
      left: auto;
      right: 0;

      &:before {
        margin-left: -10px;
        transform: rotate(-135deg);
        left: 50%;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    &__view {
      border-width: 1px 0;
      padding: 1px 0;
      order: -1;
    }

    &__paginator {
      width: 93px;
      height: 48px;
    }

    &__arrow {
      &:before {
        width: 8px;
        height: 8px;
        margin: -4px 0 0 -3px;
      }

      &.-right {
        &:before {
          margin-left: -7px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="social-links">
    <a class="social-links__link" href="//www.facebook.com/couturme">
      <img v-if="darkTheme" class="social-links__icon" src="../../../assets/front/socials/fb-white.svg" alt="fb">
      <img v-else class="social-links__icon" src="../../../assets/front/socials/fb.svg" alt="fb">
    </a>
    <a class="social-links__link" href="//www.pinterest.ru/igigistyle/couturmecom/">
      <img v-if="darkTheme" class="social-links__icon" src="../../../assets/front/socials/pinterest-white.svg" alt="Pinterest">
      <img v-else class="social-links__icon" src="../../../assets/front/socials/pinterest.svg" alt="Pinterest">
    </a>
    <a class="social-links__link" href="//www.instagram.com/couturme/">
      <img v-if="darkTheme" class="social-links__icon" src="../../../assets/front/socials/insta-white.svg" alt="Instagram">
      <img v-else class="social-links__icon" src="../../../assets/front/socials/insta.svg" alt="Instagram">
    </a>
  </div>
</template>

<script>
export default {
  props: {
    darkTheme: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.social-links {
  &__link {
    margin: 0 17px;
    float: left;
    line-height: 13px;
    text-decoration: none;
    cursor: pointer;

    &:after {
      display: none;
    }
  }

  &__icon {
    width: 13px;
    height: 13px;
    object-fit: contain;
    vertical-align: top;
  }
}
</style>

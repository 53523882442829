<template>
  <header class="home-header">
    <div class="home-header__inner">
      <div class="home-header__menu-icon" @click="onMenuPopupIconClick">
        <i class="home-header__menu-icon-line"></i>
      </div>
      <div class="home-header__menu">
        <a class="home-header__menu-item" href="#couture-for-every-woman">
          How it works
        </a>
        <a class="home-header__menu-item" href="#what-it-costs">
          What it costs
        </a>
      </div>
      <div class="home-header__logo">
        <img
          class="logo"
          src="../../../assets/front/logo.png"
          srcset="../../../assets/front/logo@2x.png 2x, ../../../assets/front/logo@3x.png 3x"
          alt="COUTURME"
        >
      </div>
      <div class="home-header__menu -right">
        <a class="home-header__menu-item" href="#customer-gallery">
          Customer gallery
        </a>
        <a class="home-header__menu-item" href="#" @click.prevent="emitDesignNowClick">
          Design Now
        </a>
        <span class="home-header__menu-item" @click="onAccountClick">
          {{ userIsLoggedIn ? 'Account' : 'Login' }}
        </span>
      </div>
    </div>
    <menu-popup
      v-if="showMenuPopup"
      :items="menuPopupItems"
      @item-click="onMenuPopupItemClick"
      @close-click="onMenuPopupCloseClick"
    />
  </header>
</template>

<script>
import MenuPopup from './MenuPopup'

export default {
  components: {
    MenuPopup
  },

  data: () => ({
    showMenuPopup: false
  }),

  computed: {
    userIsLoggedIn () {
      if (this.$store.state.user) {
        return !!this.$store.state.user.current
      }
      return false
    },

    menuPopupItems () {
      return [
        {
          name: 'How it works',
          url: '#couture-for-every-woman'
        },
        {
          name: 'What it costs',
          url: '#what-it-costs'
        },
        {
          name: 'Customer gallery',
          url: '#customer-gallery'
        },
        {
          name: 'Design Now',
          url: '#design-now'
        },
        {
          name: this.userIsLoggedIn ? 'Account' : 'Login',
          url: '/my-account'
        }
      ]
    }
  },

  methods: {
    onAccountClick () {
      if (this.userIsLoggedIn) {
        this.$router.push('/my-account')
      } else {
        this.$store.commit('ui/setAuthElem', 'login')
        this.$bus.$emit('modal-show', 'modal-signup')
      }
    },

    onMenuPopupIconClick () {
      this.showMenuPopup = true
    },

    onMenuPopupItemClick (item) {
      switch (item.url) {
        case '#design-now':
          this.emitDesignNowClick()
          break
        case '/my-account':
          this.onAccountClick()
          break
        default:
          this.$router.push(item.url)
          this.showMenuPopup = false
      }
    },

    onMenuPopupCloseClick () {
      this.showMenuPopup = false
    },

    emitDesignNowClick () {
      this.$emit('design-now-click')
    }
  }
}
</script>

<style lang="scss">
.home-header {
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 10;
  width: 100%;

  &__inner {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1;
    color: #fff;
  }

  &__menu-icon {
    display: none;
    width: 24px;
    height: 16px;
    position: absolute;
    margin-top: -12px;
    top: 50%;
    left: 32px;
    cursor: pointer;

    &:before,
    &:after,
    &-line {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      content: '';
    }

    &-line {
      top: 50%;
    }

    &:after {
      top: 100%;
    }
  }

  &__menu {
    margin: 0 2.8em;
    display: flex;
    flex: 1 1 auto;
    font: normal 10px 'Red Hat Text', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2.5px;

    &.-right {
      justify-content: flex-end;
    }
  }

  &__menu-item {
    margin: 0 2.8em;
    position: relative;
    cursor: pointer;

    &:after {
      visibility: hidden;
      position: absolute;
      bottom: -1.5em !important;
      left: 50% !important;
      right: 50% !important;
      height: 2px !important;
      width: auto !important;
      background-color: #fff !important;
      transition-property: left, right;
      transition-duration: .2s;
      transition-timing-function: ease-in-out;
      transition-delay: 0s;
      content: '';
    }
    &:hover:after {
      visibility: visible;
      left: 0.2em !important;
      right: 0.2em !important;
    }
  }

  @media (max-width: 750px) {
    &__inner {
      justify-content: center;
    }

    &__menu-icon {
      display: block;
    }

    &__menu {
      display: none;
    }
  }
}

.logo {
  width: 146px;
  height: 35px;
  vertical-align: middle;
}
</style>

<template>
  <section class="menu-popup">
    <div class="menu-popup__actions">
      <span
        class="menu-popup__close"
        @click="onCloseClick"
      >
        <i class="menu-popup__close-icon"></i>
      </span>
    </div>
    <nav class="menu-popup__menu">
      <ul class="menu-popup__list">
        <li v-for="(item, i) in items" :key="i">
          <a
            :href="item.url"
            @click.prevent="onItemClick(item)"
          >
            {{ item.name }}
          </a>
        </li>
      </ul>
    </nav>
    <div class="menu-popup__contacts">
      <social-links class="menu-popup__socials" :dark-theme="true" />
      <a class="menu-popup__email" href="mailto:help@couturme.com">help@couturme.com</a>
    </div>
  </section>
</template>

<script>
import SocialLinks from './SocialLinks'

export default {
  components: {
    SocialLinks
  },

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  methods: {
    onItemClick (item) {
      this.$emit('item-click', item)
    },

    onCloseClick () {
      this.$emit('close-click')
    }
  }
}
</script>

<style lang="scss">
.menu-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 32px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  font-family: 'Red Hat Text', sans-serif;
  background: #161616;
  color: #fff;

  &__actions {
    padding: 24px 0;
  }

  &__menu {
    font-weight: bold;
    font-size: 16px;
    line-height: 4;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__contacts {
    padding: 32px 0;
  }

  &__close {
    display: inline-block;
    margin-left: -8px;
    padding: 8px;
    cursor: pointer;
  }

  &__close-icon {
    display: inline-block;
    position: relative;
    width: 25px;
    height: 25px;
    transform: rotate(45deg);

    &:before {
      width: 1px;
      height: 100%;
      position: absolute;
      top: 1px;
      left: 50%;
      background: #fff;
      content: '';
    }

    &:after {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 1px;
      background: #fff;
      content: '';
    }
  }

  &__socials {
    display: inline-block;
    vertical-align: middle;

    .social-links__link:first-child {
      margin-left: 0;
    }

    .social-links__link:last-child {
      margin-right: 4em;
    }
  }

  &__email {
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: -0.23px;
  }
}
</style>

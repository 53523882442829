<template>
  <footer class="home-footer">
    <div class="home-main__section">
      <div class="home-footer__slogan">Turn Your dream gown into&nbsp;a&nbsp;reality!</div>
      <div class="footer-advantages">
        <div class="footer-advantages__item">
          <div class="footer-advantages__icon -luxury"></div>
          <div class="footer-advantages__title">
            Affordable<br>Luxury
          </div>
          <div class="footer-advantages__text">
            We&nbsp;offer high fashion designer quality dresses for a&nbsp;fraction of&nbsp;the retail price
          </div>
        </div>
        <div class="footer-advantages__item">
          <div class="footer-advantages__icon -exclusive"></div>
          <div class="footer-advantages__title">
            Exclusive<br>Design
          </div>
          <div class="footer-advantages__text">
            We&nbsp;combine unique technology with 4-time award winning fashion design and creative direction
            to&nbsp;create the unforgettable dress just for You
          </div>
        </div>
        <div class="footer-advantages__item">
          <div class="footer-advantages__icon -comfort"></div>
          <div class="footer-advantages__title">
            Comfort<br>And Perfect Fit
          </div>
          <div class="footer-advantages__text">
            Our patent-pending software allows&nbsp;us to&nbsp;custom fit your body to&nbsp;perfection. We&nbsp;select
            luxurious fabrics for effortless comfort and ease.
          </div>
        </div>
        <div class="footer-advantages__item">
          <div class="footer-advantages__icon -guarantee"></div>
          <div class="footer-advantages__title">
            100% Money<br>Back Guarantee
          </div>
          <div class="footer-advantages__text">
            We&nbsp;stand by&nbsp;the quality of&nbsp;our designs and are committed to&nbsp;Your utmost happiness
          </div>
        </div>
      </div>
      <button
        class="home-footer__button"
        @click.prevent="$emit('design-now-click')"
      >
        Design your dress
      </button>
    </div>
    <div class="footer-line">
      <div class="footer-line__logo">
        <img
          class="footer-logo"
          src="../../../assets/front/logo.png"
          srcset="../../../assets/front/logo@2x.png 2x, ../../../assets/front/logo@3x.png 3x"
          alt="COUTURME"
        >
      </div>
      <div class="footer-line__menu footer-menu">
        <span class="footer-menu__item">
          <router-link to="/faq">FAQ</router-link>
        </span>
        <span class="footer-menu__item">
          <router-link to="/about">Our Mission</router-link>
        </span>
        <span class="footer-menu__item">
          <router-link to="/technology">Technology</router-link>
        </span>
      </div>
      <social-links class="footer-line__socials" :dark-theme="true" />
    </div>
    <div class="footer-copyright">
      <div class="footer-copyright__address">
        1900 South Norfolk Street #305,<br>
        San Mateo, CA 94403<br>
        Monday-Friday, 9am-5pm PST<br>
        <a href="mailto:help@couturme.com"><b>help@couturme.com</b></a>
      </div>
      <div class="footer-copyright__items">
        <div class="footer-copyright__item">A Y Combinator Company</div>
        <div class="footer-copyright__item">
          <router-link to="/privacy">Privacy policy</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import SocialLinks from './SocialLinks'

export default {
  components: {
    SocialLinks
  }
}
</script>

<style lang="scss">
.home-footer {
  padding: 130px 40px 37px;
  font: 16px/1.5 'Red Hat Text', sans-serif;
  color: #fff;
  background-color: #161616;

  &__slogan {
    margin-bottom: 126px;
    text-align: center;
    font: 100 40px/1.6 'Cormorant Garamond', serif;
    letter-spacing: -0.1px;
  }

  &__button {
    border: solid 2px rgba(255, 255, 255, 0.2);
    padding: 0;
    width: 100%;
    height: 63.8px;
    font: normal 10px/1.5 'Red Hat Text';
    letter-spacing: 2.5px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: #161616;
  }

  @media (max-width: 600px) {
    padding-top: 64px;

    &__slogan {
      margin-bottom: 56px;
      font-size: 24px;
    }
  }
}

.footer-advantages {
  margin-bottom: 102px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  letter-spacing: -0.3px;

  &__item {
    margin-left: 2em;
    max-width: 210px;

    &:first-child {
      margin: 0;
    }
  }

  &__icon {
    margin: 0 auto 24px;
    width: 48px;
    height: 48px;
    background: 50% 50% no-repeat;
    background-size: contain;

    &.-luxury {
      background-image: url('../../../assets/front/footer/luxury.svg');
    }
    &.-exclusive {
      background-image: url('../../../assets/front/footer/exclusive.svg');
    }
    &.-comfort {
      background-image: url('../../../assets/front/footer/comfort.svg');
    }
    &.-guarantee {
      background-image: url('../../../assets/front/footer/guarantee.svg');
    }
  }

  &__title {
    margin-bottom: 16px;
    font: bold 24px/1.33 'Cormorant Garamond', serif;
    letter-spacing: -0.3px;
  }

  &__text {
    font-weight: 100;
    opacity: 0.8;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;

    &__item {
      margin: 64px 0 0;
    }
  }

  @media (max-width: 400px) {
    &__title {
      font-size: 20px;
      line-height: 1.6;
      letter-spacing: -0.25px;
    }
  }
}

.footer-line {
  margin: 188px 0 79px;
  display: flex;

  &__logo {
    margin-right: auto;
  }

  &__socials {
    margin-left: 108px;
  }

  @media (max-width: 900px) {
    margin-top: 110px;
    flex-direction: column;
    align-items: center;

    &__logo {
      margin: 0 0 40px;
    }

    &__socials {
      margin: 40px 0 0;
    }
  }
}

.footer-logo {
  width: 146px;
  height: 35px;
  vertical-align: middle;
}

.footer-menu {
  font-size: 10px;
  font-weight: normal;
  letter-spacing: 2.5px;
  text-transform: uppercase;

  &__item {
    margin-left: 4em;

    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: 900px) {
    &__item {
      display: block;
      margin: 0 0 2em;
      text-align: center;
    }
  }
}

.footer-copyright {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  opacity: 0.3;
  font-size: 12px;
  font-weight: normal;
  line-height: 2;
  letter-spacing: -0.23px;

  &__address {
    margin-right: 50px;
    flex: 1 1 100%;
  }

  &__items {
    margin-top: 40px;
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
  }

  &__item {
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;

    &__address {
      margin-right: 0;
      text-align: center;
    }
  }
}
</style>
